@charset "UTF-8";
$navbar-background-color: #FC6E51;

@import

  // Variables, Extends, Mixins
  "bootflat/global",

  // Base
  "bootflat/typography",
  "bootflat/button",
  "bootflat/button_group",
  "bootflat/label_badge",
  "bootflat/tooltip",
  "bootflat/popover",
  "bootflat/progress",
  "bootflat/breadcrumb",
  "bootflat/pagination",
  "bootflat/pager",
  "bootflat/form",
  "bootflat/stepper",
  "bootflat/selecter",
  "bootflat/checkbox_radio",
  "bootflat/toggle",
  "bootflat/calendar",
  "bootflat/pricing",
  "bootflat/alert",
  "bootflat/tab",
  "bootflat/pill",
  "bootflat/navbar",
  "bootflat/list",
  "bootflat/media_list",
  "bootflat/modal",
  "bootflat/well",
  "bootflat/thumbnail",
  "bootflat/jumbotron",
  "bootflat/panel",
  "bootflat/accordion",
  "bootflat/footer",
  "bootflat/timeline",
  "bootflat/dropdown";